<template>
  <div class="services">
    <b-container>
      <h1 class="text-center mb-4"><span class="blue-text">Услуги</span> и прайс</h1>

      <article class="about-services text-center mb-4">Мы проводим электромонтажные работы любой сложности: монтаж внутренней и наружной электрики (электропроводки), монтаж электрооборудования, проводим электромонтажные работы в квартирах, офисах, коттеджах</article>

      <button class="d-flex download-price mx-auto">
        <span class="material-icons-outlined my-auto mr-2">file_download</span> Скачать полый прайс (.pdf)
      </button>

      <div class="services-table">
        <b-table hover class="mt-4" :items="services" :fields="fields">
          <template #cell(open)="data">
            <router-link :to="data.item.open" v-if="data.item.open">
              <span class="material-icons-round mt-auto mb-auto mr-2">chevron_right</span>
            </router-link>
          </template>
        </b-table>
      </div>
    </b-container>
  </div>
</template>

<script>
export default {
  name: "Services",
  data() {
    return {
      fields: [{key: 'title', label: 'Название'}, {key: 'cost', label: 'Цена, р.'}, {key: 'open', label: ''}],
      services: [
        {
          cost: 150,
          title: 'Демонтаж точки',
          count: 1
        },
        {
          cost: 150,
          title: 'Монтажы кабеля (закрытый) (м/п)',
          count: 1,
          open: '/service/mount'
        },
        {
          cost: 100,
          title: 'Монтаж кабеля (открытый) (м/п)',
          count: 1,
          open: '/service/mount'
        },
        {
          cost: 300,
          title: 'Расключение распаечной коробки',
          count: 1
        },
        {
          cost: 3000,
          title: 'Сверление отверстий (м/п)',
          count: 1
        },
        {
          cost: 300,
          title: 'Установка автомата защиты',
          count: 1,
          open: '/service/installation'
        },
        {
          cost: 500,
          title: 'Установка вентилятора канальтоного',
          count: 1
        },
        {
          cost: 300,
          title: 'Установка выключателя',
          count: 1,
          open: '/service/installation'
        },
        {
          cost: 200,
          title: 'Установка подрозетника',
          count: 1,
          open: '/service/installation'
        },
        {
          cost: 200,
          title: 'Установка распаечной коробки',
          count: 1,
          open: '/service/installation'
        },
        {
          cost: 1000,
          title: 'Установка распределительного щита',
          count: 1,
          open: '/service/installation'
        },
        {
          cost: 300,
          title: 'Установка розетки',
          count: 1,
          open: '/service/installation'
        },
        {
          cost: 500,
          title: 'Установка светильников',
          count: 1,
          open: '/service/installation'
        },
        {
          cost: 1000,
          title: 'Установка счетчика электроэнергии',
          count: 1,
          open: '/service/installation'
        },
        {
          cost: 500,
          title: 'Установка УЗО, дифавтомат',
          count: 1,
          open: '/service/installation'
        },
        {
          cost: 500,
          title: 'Штробление под кабель в потолке (м/п)',
          count: 1,
          open: '/service/shtroblenie'
        },
        {
          cost: 200,
          title: 'Штробление под кабель в стене (м/п)',
          count: 1,
          open: '/service/shtroblenie'
        },
        {
          cost: 800,
          title: 'Штробление под канал в потолке',
          count: 1,
          open: '/service/shtroblenie'
        },
        {
          cost: 400,
          title: 'Штробление под канал в стене',
          count: 1,
          open: '/service/shtroblenie'
        },
        {
          cost: 300,
          title: 'Штробление под подрозетник',
          count: 1,
          open: '/service/shtroblenie'
        },
        {
          cost: 500,
          title: 'Штробление под распаячную коробку',
          count: 1,
          open: '/service/shtroblenie'
        },
        {
          cost: 2,
          title: 'Штробление под щит (за см²)',
          count: 1,
          open: '/service/shtroblenie'
        },
      ],
    }
  }
}
</script>

<style scoped lang="scss">
.services{
  padding-top: 110px;
}

.about-services{
  font-size: 20px;
}

.download-price{
  padding: 10px 30px;
  border: none;
  outline: none;
  color: white;
  background: linear-gradient(93.86deg, #3CB3FF -1.19%, #49B8FF -1.18%, #98D7FF 133.59%);
  border-radius: 20px;
}

.services-table{
  overflow-x: auto;
}
</style>